@import "skeleton-screen-css";

.container-fluid {
  position: relative;
  background-color: #f9f9f9;
  font-family: 'PT Sans', sans-serif;
}

aside {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 80px;
  overflow:hidden;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 4px 5px 0px;
  background-color: white;
  z-index:100;
}

#elenco-sezioni li {
  display: none!important;
  text-align: left;
  font-size:2rem;
  font-weight: bold;
  line-height: 80px;
}

#elenco-sezioni li.current {
  display: block!important;
}



main {
  position: absolute;
  top: 0;
  left: 0;
  flex: 1;
  height: 100vh;
  z-index:1;
  padding-top: 80px;
  width: 100%;
}

.sp_logo {
  width: 12rem;
}

#logo-container {
  display: none;
}

.sp_form {
  padding:1rem;
  width: 100%;
}

.sp_form .form-control-lg {
  padding:1.1rem 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 1000px) {
  aside {
    position: fixed;
    top:0;
    left: 0;
    width: 300px;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 4px 5px 0px;
    background-color: white;
    z-index:100;
  }

  main {
    margin-left: 300px;
    width: calc(100vw - 350px);
    padding-top: 0;
    display: grid;
    place-items: center;
  }

  #logo-container {
    display: block;
  }

  #elenco-sezioni li {
    display: block!important;
    line-height: 2rem;
    font-weight: normal;
    font-size:1rem;
  }

  .sp_form {
    max-width: 800px;
    margin: 0 auto;
  }
  
  
}